import React, { FC, useContext } from 'react';
import AudioPlayerContext from '../../context/AudioPlayerContext';
import Icon from '../Icons/Icon';
import IconPlay from 'components/Icons/IconPlay';
import { removeZeroFromDuration } from 'utils/utils';
import IconPauseRedBtn from 'components/Icons/IconPauseRedBtn';

interface Props {
  audioSource: string;
  audioSubtitle?: string;
  audioTitle?: string;
  extraText?: string;
  elementClass?: string;
  label?: string;
  size?: string;
  showTitle?: boolean;
  showDuration?: string;
  iconWidth?: string;
  playBtnSm?: boolean;
  audioThumbnailSrc?: string;
  audioThumbnailAlt?: string | null;
}

const AudioPlayButton: FC<Props> = ({
  audioSource,
  audioSubtitle,
  audioTitle,
  extraText,
  audioThumbnailSrc,
  audioThumbnailAlt,
  label,
  size,
  showTitle = false,
  showDuration,
  iconWidth = '1.25rem',
  playBtnSm,
}) => {
  if (audioSource?.match(/\.m3u/)) {
    return null;
  }

  const AudioContext = useContext(AudioPlayerContext);
  const buttonInner = (context: any) /* TODO: make it not any */ => {
    if (context.isAudioPlaying && audioSource === context.audioSource) {
      return (
        <div className="btn-round-inner btn-round-inner-pause">
          <Icon name="pause" />
          {!label && <span className="invisible">Pause</span>}
        </div>
      );
    } else {
      return (
        <div className="btn-round-inner btn-round-inner-play">
          <IconPlay
            color="var(--color-white)"
            width={iconWidth}
            height={iconWidth}
          />
          {!label && <span className="invisible">Play</span>}
        </div>
      );
    }
  };

  const buttonInnerTeaser = (context: any) /* TODO: make it not any */ => {
    if (context.isAudioPlaying && audioSource === context.audioSource) {
      return (
        <div className="">
          <IconPauseRedBtn width={iconWidth} height={iconWidth} />
          {!label && <span className="invisible">Pause</span>}
        </div>
      );
    } else {
      return (
        <div className="">
          <IconPlay width={iconWidth} height={iconWidth} />
          {!label && <span className="invisible">Play</span>}
        </div>
      );
    }
  };

  const style = () => {
    if (size) {
      return { height: size, width: size };
    }
  };

  const updateAudio = async (
    audioSource: string,
    audioTitle: string | undefined,
    audioSubtitle: string,
    audioThumbnailSrc: string | undefined,
    audioThumbnailAlt: string | undefined | null
  ) => {
    await AudioContext.setAudio(
      'https://dts.podtrac.com/redirect.mp3/' + audioSource,
      audioTitle,
      audioSubtitle,
      audioThumbnailSrc,
      audioThumbnailAlt
    );

    if (audioSource != AudioContext.audioSource) {
      AudioContext.pause();
      AudioContext.play();
    } else {
      AudioContext.togglePlaying();
    }
  };

  return (
    <AudioPlayerContext.Consumer>
      {(context) => (
        <button
          type="button"
          className={`btn-round ${context.isAudioPlaying ? 'playing' : ''} ${
            playBtnSm ? 'btn-audio-sm' : ''
          }`}
          onClick={(e) => {
            e.preventDefault();
            updateAudio(
              audioSource,
              audioTitle,
              audioSubtitle ?? ' ',
              audioThumbnailSrc,
              audioThumbnailAlt
            );
          }}
        >
          <span style={style()}>
            {playBtnSm ? buttonInnerTeaser(context) : buttonInner(context)}
          </span>
          {label && (
            <b>
              {context.isAudioPlaying && context.audioSource === audioSource
                ? 'Pause'
                : label}
            </b>
          )}
          <span>
            {showDuration && (
              <>{` [${removeZeroFromDuration(showDuration)}] `}</>
            )}
            {showTitle && (
              <span
                className="audio-player-title"
                aria-label={`- ${audioTitle}`} /* dash adds pause for screen readers */
              >
                {audioTitle}
              </span>
            )}
            {extraText && <span>{extraText}</span>}
          </span>
        </button>
      )}
    </AudioPlayerContext.Consumer>
  );
};

export default AudioPlayButton;
